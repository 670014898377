
import { defineComponent, ref, reactive, toRefs, computed, onMounted, nextTick } from 'vue'
import uploadImage from '@/views/courseManger/componets/upload-image.vue'
import { SEND_TYPE, OSSURL } from '@/hooks/ali-oss'
import { EXERISE, EXERISE_TAG } from '@/hooks/config'
import { useRoute, useRouter } from 'vue-router'
import { RuleObject } from 'ant-design-vue/es/form/interface'
import { exerciseTag, createExe, fetchExerciseDetail, putExercise } from '@/api/index'
import { message } from 'ant-design-vue'
import previewDialog from '@/views/examManager/components/previewDialog.vue'
let routerName: any = ''
const columns = [
  {
    title: '选项',
    align: 'center',
    width: 80,
    customRender: ({ index }: { index: number }) => String.fromCharCode(index + 65)
  },
  {
    title: '选项文字',
    dataIndex: 'content.text',
    slots: { customRender: 'text' }
  },
  {
    title: '图片',
    dataIndex: 'content.image',
    slots: { customRender: 'image' }
  },
  {
    title: '是否正确',
    dataIndex: 'content.right',
    slots: { customRender: 'right' },
    align: 'center'
  }
]
export interface FileProps {
  file?: object;
  file_name?: string;
  file_path?: string;
  file_size?: string;
  file_type?: string;
  url?: string;
  [key: string]: any;
}

export interface ListProps2 {
  text: string;
  image: string;
  video: string;
  audio: string;
  events: string;
}
export interface ListProps {
  id: number;
  right: boolean;
  content: ListProps2;
}
export default defineComponent({
  name: 'createChoice',
  props: ['id'],
  components: { uploadImage, previewDialog },
  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const formRef = ref()
    const typeList = ref(EXERISE)
    const tagList = ref()
    const formState = reactive({
      tagId: 1,
      typeId: typeList.value.find(item => item.router === route.name)?.id,
      title: '',
      remarks: '',
      selectList: [{
        id: 0,
        right: false,
        list: [],
        content: {
          text: '',
          image: '',
          video: '',
          audio: '',
          events: null
        }
      }, {
        id: 1,
        right: false,
        list: [],
        content: {
          text: '',
          image: '',
          video: '',
          audio: '',
          events: null
        }
      }, {
        id: 2,
        right: false,
        list: [],
        content: {
          text: '',
          image: '',
          video: '',
          audio: '',
          events: null
        }
      }, {
        id: 3,
        right: false,
        list: [],
        content: {
          text: '',
          image: '',
          video: '',
          audio: '',
          events: null
        }
      }]
    })
    const getExamTag = () => {
      exerciseTag({}).then(res => {
        tagList.value = res.data
        formState.tagId = tagList.value[0].id
      })
    }
    const checkSelectList = async (rule: RuleObject, value: any) => {
      const arr = value.map((x: any) => (x.content.text.trim() || x.content.image).length > 0 ? 1 : 0)
      const _lastLen = arr.lastIndexOf(1) + 1
      const _allLen = value.filter((item: any) => item.content.text.trim().length || item.content.image.length).length
      if (!(_lastLen === _allLen && _allLen >= 2)) {
        return Promise.reject(new Error('格式不正确，请检查'))
      }
      let _isRightIndex = 0
      const _isRightLen = value.filter((item: any, index: number) => {
        if (item.right === true) {
          _isRightIndex = index
        }
        return (item.right === true)
      })
      if (_isRightLen.length === 0) {
        return Promise.reject(new Error('请至少设置一个正确答案'))
      }
      if (_isRightIndex + 1 > _lastLen) {
        return Promise.reject(new Error('设置的答案有问题，请检查'))
      }
      return Promise.resolve()
    }
    // 表单验证
    const rules = {
      typeId: [{ required: true, message: '请选择题目类型', type: 'number' }],
      tagId: [{ required: true, message: '请选择习题标签', type: 'number' }],
      title: [{ required: true, message: '请输入题干', whitespace: true }],
      selectList: [{ required: true, validator: checkSelectList, whitespace: true }],
      answer: [{ required: true }]
    }
    const upload1 = ref()
    const upload2 = ref()
    const listIcon: FileProps = ref([])
    const listImageTarget: FileProps = ref([])
    const upObj = ref(SEND_TYPE)
    const changeFileIcon = (lists: []) => {
      listIcon.value = lists
    }
    const changeFileImageTarget = (lists: []) => {
      listImageTarget.value = lists
    }
    const resetListIcon = () => {
      listIcon.value.pop()
    }
    const resetImageTarget = () => {
      listImageTarget.value.pop()
    }
    const changeTag = (value: number) => {
      formState.tagId = value
    }
    const iconUrl = computed(() => {
      if (listIcon.value.length === 0) {
        return ''
      } else {
        return (listIcon.value[0] as FileProps).file_path
      }
    })
    const imageTarget = computed(() => {
      if (listImageTarget.value.length === 0) {
        return ''
      } else {
        return (listImageTarget.value[0] as FileProps).file_path
      }
    })
    // 获取习题内容
    const getData = () => {
      const _question = {
        text: formState.title,
        image: iconUrl.value,
        audio: '',
        video: imageTarget.value,
        events: null
      }
      const _answers: any[] = []
      formState.selectList.filter((item: any) => {
        if (item.content.text || item.content.image) {
          _answers.push({
            right: item.right,
            content: {
              text: item.content.text,
              image: item.content.image,
              video: item.content.video,
              audio: item.content.audio,
              events: item.content.events
            }
          })
        }
      })
      return JSON.stringify({
        question: _question,
        answers: _answers
      })
    }
    const changeTableFile = (lists: [], record: any) => {
      if (lists.length > 0) {
        record.list = lists
        record.content.image = record.list[0].file_path
      }
    }
    // 删除表格里面的图片
    const deleteTableFile = (record: any) => {
      record.list.pop()
      record.content.image = ''
    }
    // 重置页面数据
    const resetData = () => {
      formRef.value.resetFields()
      listIcon.value = []
      listImageTarget.value = []
    }
    // 预览弹出层
    const previewVisible = ref(false)
    const previewId = ref(0)
    const openPreview = (id: number) => {
      previewId.value = id
      previewVisible.value = true
    }
    const onSubmit = () => {
      formRef.value.validate().then(() => {
        createExe({
          type: formState.typeId,
          tag_id: formState.tagId,
          remarks: formState.remarks,
          content: getData()
        }).then((res) => {
          openPreview(res.data)
          // message.success('创建成功')
          // router.push({ name: 'ExerciseManager' })
          // resetData()
        })
      })
    }
    // 初始化页面
    const fetchInitData = () => {
      fetchExerciseDetail({
        id: props.id
      }).then(res => {
        const _xtData = JSON.parse(res.data.content)
        listIcon.value = _xtData.question.image ? [{
          url: `${OSSURL}/${_xtData.question.image}`,
          file_type: 'p1',
          file_path: _xtData.question.image
        }] : []
        listImageTarget.value = _xtData.question.video ? [{
          url: `${OSSURL}/${_xtData.question.video}`,
          file_type: 'm4',
          file_path: _xtData.question.video
        }] : []
        formState.tagId = res.data.tag_id
        formState.typeId = res.data.type
        formState.title = _xtData.question.text
        formState.remarks = res.data.remarks
        _xtData.answers.map((item: any, index: number) => {
          item.id = index
          if (item.content.image.length) {
            item.list = [{
              url: `${OSSURL}/${item.content.image}`,
              file_type: 'p1',
              file_path: item.content.image
            }]
          } else {
            item.list = []
          }
          formState.selectList[index] = item
        })
      })
    }
    // 编辑页面
    const handleEdit = () => {
      formRef.value.validate().then(() => {
        putExercise({
          id: props.id,
          type: formState.typeId,
          tag_id: formState.tagId,
          remarks: formState.remarks,
          content: getData()
        }).then(() => {
          openPreview(props.id)
          // message.success('编辑成功')
          // console.log(router, router)
          // router.back()
          // router.push({ name: 'ExerciseManager' })
        })
      })
    }
    const handelCancel = () => {
      router.replace({ name: routerName.name, params: { id: routerName.params.id } })
    }
    onMounted(() => {
      getExamTag()
      if (props.id) {
        fetchInitData()
      }
    })
    return {
      previewVisible,
      previewId,
      formRef,
      rules,
      // getTypeLabel,
      formState,
      columns,
      ...toRefs(formState),
      // ...toRefs(tableData),
      upload1,
      upload2,
      listIcon,
      listImageTarget,
      upObj,
      changeFileIcon,
      changeFileImageTarget,
      resetListIcon,
      resetImageTarget,
      typeList,
      tagList,
      changeTag,
      onSubmit,
      changeTableFile,
      deleteTableFile,
      handleEdit,
      handelCancel
    }
  },
  beforeRouteEnter (to, from, next) {
    routerName = from
    next()
  }
})
